import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/App/Navbar'
import Footer from '../components/App/Footer'
import AuthorComponent from '../components/Author/AuthorComponent'
import Header from '../components/App/Header'

const Blog = ( context ) => {
  const authorDetail = context?.pageContext?.author
  const { metaTitle, metaDescription, slug } = authorDetail

  return (
    <Layout>
      <Header metaTitle={metaTitle} metaDescription={metaDescription} slug={slug} />
      <Navbar />
      <AuthorComponent authorDetail={authorDetail} />
      <Footer />
    </Layout>
    )
}

export default Blog