import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import AuthorBlogs from './AuthorBlogs'

const AuthorComponent = ({ authorDetail }) => {
    return (
        <section className="author-detail pb-70">
            <div className="container">
                <div className='title-content mb-2'>
                    <h2 className='blog-heading'>
                        <img src={starIcon} alt="author" />Author
                    </h2>
                </div>
                <div className='row align-items-center my-4'>
                    <div className='col-lg-4 col-md-4'>
                        <div className='authorImage'>
                            <img src={authorDetail?.authorImage?.url} alt='authorImage' />
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8'>
                        <div className='flex flex-col'>
                            <h2 className='mb-4'>{authorDetail?.authorName}</h2>
                            <p>{authorDetail?.authorIntro}</p>
                        </div>
                    </div>
                </div>
                {authorDetail?.blogs?.length ?
                    <div className='mt-10'>
                        <h3 className='my-3 text-center'>Other  blogs by {authorDetail?.authorName}</h3>
                        <AuthorBlogs blogs={authorDetail?.blogs} />
                    </div> : ''
                }

            </div>
        </section>
    )
}

export default AuthorComponent;
