import React from 'react'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

const AuthorBlogs = ({ blogs }) => {

    const truncateString = (str, num) => {
        return num >= str.length ? str : str.slice(0, num) + '...'
    }
    const sortedBlogs = blogs?.sort((a, b) =>  (new Date(a?.date)) - (new Date(b?.date)))

    return (
        <div className='row justify-content-center'>
            {sortedBlogs?.map((blog, index) =>
                <div key={index} className='col-lg-4 col-md-6 col-sm-6 my-4'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='card-title'>
                                <h5 className='text-center'>{blog?.metaTitle}</h5>
                            </div>
                            <div className='card-image mb-3'>
                                <img src={blog?.cardImage?.url} alt={blog?.cardImage?.alternativeText} />
                            </div>

                            {blog?.contentBody?.map((body, index) => {
                                return (
                                    body?.viewOnCard ?
                                        <div key={index} className='card-content'>
                                            <span className="card-subtitle mr-2"> {body?.head2} </span>
                                            <ReactMarkdown>{truncateString(body?.paragraph?.data?.paragraph, 130)}</ReactMarkdown>
                                        </ div> : ''
                                )
                            })}
                            <span className='d-flex justify-content-end'>
                                <Link to={blog?.slug} className='default-btn my-3'>
                                    <span></span>
                                    Continue Reading
                                    <i className='bx bx-chevron-right'></i>
                                </Link>
                            </span>
                            <div className='aboutBlog-info d-flex flex-row-reverse'>
                                <span className='text-sm'>
                                    <i className='bx bx-calendar'></i> {blog?.date}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AuthorBlogs